.body {
  margin-bottom: 58px;

  @media (max-width: 1200px) {
    margin-bottom: 41px;
  }

  @media (max-width: 768px) {
    margin-bottom: 27px;
  }

  & h5 {
    display: flex;
    align-items: center;
    column-gap: 12px;

    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    background: var(--gradient_text);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    margin-bottom: 16px;
  }
  & p {
    margin-bottom: 30px;
  }

  & span {
    color: #363940;
  }
}
