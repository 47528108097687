.body {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;

  padding: 40px 0px;

  &::before {
    content: '';
    position: absolute;
    pointer-events: none;
    top: -179px;
    left: -145px;
    width: 633px;
    height: 738px;
    background-image: url('../../../../../../public/assets/img/landing/s_decoration.svg');
    background-repeat: no-repeat;
    // background-position: center center;
    // background-size: contain;
  }

  // &::after {
  //   content: '';
  //   position: absolute;
  //   pointer-events: none;
  //   top: -384px;
  //   right: -594px;

  //   // transform: translate(50%, 50%);
  //   width: 1200px;
  //   height: 1200px;
  //   background-image: url('../../../../../../public/assets/img/landing/ellipse.svg');
  //   background-size: contain;
  //   background-repeat: no-repeat;

  //   @media (max-width: 768px) {
  //     top: -484px;
  //     right: -494px;
  //   }
  // }

  &_left {
    &_info {
      max-width: 470px;
      margin-bottom: 24px;

      @media (max-width: 1200px) {
        text-align: center;
      }
      & h4 {
        margin-bottom: 5px;
        text-transform: uppercase;
      }

      & h1 {
        margin-bottom: 12px;
      }

      & p:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    &_buttons {
      margin-top: 24px;
      display: flex;
      align-items: center;
      column-gap: 40px;

      @media (max-width: 1200px) {
        justify-content: center;
      }

      @media (max-width: 550px) {
        flex-direction: column;
        row-gap: 24px;
      }

      &_button_inner {
        color: var(--bg);
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
      }

      &_youtube {
        display: flex;
        align-items: center;
        column-gap: 16px;

        & span {
          transition: all 0.3s ease 0s;
        }

        @media (any-hover: hover) {
          &:hover {
            & span {
              color: var(--beige2);
            }
          }
        }
      }
    }
  }

  &_right {
    position: relative;
    min-width: 677px;
    height: 420px;
    margin-right: -32px;

    @media (max-width: 1300px) {
      margin-right: -15px;
    }

    @media (max-width: 1200px) {
      min-width: 0px;
      max-width: 677px;
      height: auto;
    }

    @media (max-width: 550px) {
      align-self: end;
    }

    &_img {
      max-width: 677px;
      width: 100%;
      height: auto;

      @media (max-width: 550px) {
        display: none;
      }

      &_mobile {
        display: none;
        margin-left: auto;
        max-width: 400px;
        width: 100%;
        @media (max-width: 550px) {
          display: block;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    row-gap: 58px;
  }

  @media (max-width: 550px) {
    row-gap: 30px;

    padding: 30px 0px;
  }

  &_decorations {
    pointer-events: none;
    position: absolute;
    width: 1635px;
    height: calc(100% + 70px);
    z-index: -1;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 1635px) {
      width: 100vw;
    }

    &_decoration {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 100%
      );
      width: 1px;
      height: 200px;
      position: absolute;
      top: 0;
      left: 0;

      &::after {
        content: '';
        display: block;
        position: absolute;

        left: 1px;
        bottom: -10.5px;
        width: 6px;
        height: 6px;
        transform: rotate(45deg) translateX(-5px);
        background-color: rgba(255, 255, 255, 0.1);
      }

      &:nth-child(1) {
        @media (max-width: 768px) {
        }
        height: 378px;
        top: 0px;
        left: 2%;

        @media (max-width: 550px) {
          display: none;
        }
      }
      &:nth-child(2) {
        height: 657px;
        top: 0px;
        left: calc((217 / 1635) * 100%);

        @media (max-width: 550px) {
          left: 2%;
        }
      }
      &:nth-child(3) {
        height: 168px;
        top: 0px;
        left: calc((526 / 1635) * 100%);

        @media (max-width: 550px) {
          height: 117px;
        }
      }
      &:nth-child(4) {
        height: 400px;
        top: 0px;
        left: calc((835 / 1635) * 100%);

        @media (max-width: 550px) {
          height: 397px;
        }
      }
      &:nth-child(5) {
        height: 101px;
        top: 0px;
        left: calc((1134 / 1635) * 100%);

        @media (max-width: 103px) {
          height: 397px;
        }
      }
      &:nth-child(6) {
        height: 620px;
        top: 0px;
        left: calc((1453 / 1635) * 100%);

        @media (max-width: 550px) {
          left: calc((1605 / 1635) * 100%);
        }
      }
      &:nth-child(7) {
        height: 190px;
        top: 0px;
        left: calc((1605 / 1635) * 100%);
        @media (max-width: 550px) {
          display: none;
        }
      }
    }
  }
}

.cards {
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 31px 52px;
  margin-bottom: 120px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 31px 20px;
    margin-bottom: 100px;
  }

  & img {
    width: 25px;
    height: 25px;
  }

  &_card {
    display: flex;
    align-items: center;
    column-gap: 18px;

    &_separator {
      width: 1px;
      height: 64px;
      margin: -20px 0px;

      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0) 100%
      );

      @media (max-width: 1000px) {
        height: 180px;
      }

      @media (max-width: 768px) {
        &:nth-child(4) {
          margin: 30px 0px;
          width: 100%;
          height: 1px;

          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.1) 50%,
            rgba(255, 255, 255, 0) 100%
          );
        }

        &:nth-child(2) {
          margin-left: -2px;
        }
      }
    }

    &_icon {
      border-radius: 8px;
      width: 46px;
      height: 46px;
      display: flex;

      position: relative;
      backdrop-filter: blur(20px);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0) 100%
      );

      &_shadow {
        position: absolute;
        width: 32px;
        height: 32px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        z-index: -1;
        // filter: blur(14px);
      }
      &::after {
        content: '';

        background-image: url('../../../../../../public/assets/img/icons/user.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
      & img {
        width: 100%;
        height: auto;
      }
    }

    &_info {
      & p {
        font-size: 12px;
        font-weight: 300;
        line-height: 15px;
        color: var(--grey);
        margin-bottom: 5px;
      }

      & span {
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        background: var(--gradient_text);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    @media (max-width: 1000px) {
      flex-direction: column;
      text-align: center;
      row-gap: 13px;
    }
  }
}

.test {
  padding: 50px;
  margin-top: 20px;
  position: relative;
  backdrop-filter: blur(20px);
  background: #ffffff05;
  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    // backdrop-filter: blur(20px);

    background: inherit;
    filter: blur(5px);
    z-index: -1;
  }
}
