.wrapper {
  position: relative;
}

.decorations {
  pointer-events: none;
  position: absolute;
  width: 1635px;
  height: calc(100% + 70px);
  z-index: -1;
  top: -70px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1200px) {
    width: 100vw;
  }

  @media (max-width: 700px) {
    display: none;
  }

  &_decoration {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
    width: 1px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;

    &::after {
      content: '';
      display: block;
      position: absolute;

      left: 1px;
      bottom: -10.5px;
      width: 6px;
      height: 6px;
      transform: rotate(45deg) translateX(-5px);
      background-color: rgba(255, 255, 255, 0.1);
    }

    &:nth-child(1) {
      height: 524px;
      top: 454px;
      left: 13%;

      @media (max-width: 1200px) {
        left: 8px;
        top: 69%;
      }
    }

    &:nth-child(2) {
      height: 168;
      top: 404px;
      left: calc((500 / 1635) * 100%);

      @media (max-width: 1200px) {
        top: 58%;
        left: 15%;
      }
    }

    &:nth-child(3) {
      height: 378px;
      top: 760px;
      left: calc((756 / 1635) * 100%);

      @media (max-width: 1200px) {
        display: none;
      }
    }

    &:nth-child(4) {
      height: 310px;
      top: 690px;
      left: calc((1055 / 1635) * 100%);

      @media (max-width: 1200px) {
        display: none;
      }
    }

    &:nth-child(5) {
      height: 101px;
      top: 0px;
      left: calc((1134 / 1635) * 100%);

      @media (max-width: 1200px) {
        display: none;
      }
    }

    &:nth-child(6) {
      height: 620px;
      top: 450px;
      left: calc((1425 / 1635) * 100%);

      @media (max-width: 1200px) {
        top: 1070px;
        left: 99.2%;
      }
    }
  }
}
