.body {
  margin-bottom: 182px;

  @media (max-width: 650px) {
    margin-bottom: 100px;
  }

  &_header {
    text-align: center;
    & h2 {
      margin-bottom: 16px;
    }

    & p {
      max-width: 866px;
      margin: 0 auto 32px;
    }
  }

  &_cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 30px;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
      gap: 20px;
    }

    @media (max-width: 650px) {
      gap: 16px;
    }

    &_wrapper {
      height: 100%;
      &:nth-child(1) {
        padding: 24px 40px 24px 0px;
        .body_cards_card_img_wrapper {
          margin-left: 24px;
        }
        & img {
          max-width: 168px;
          width: 100%;
        }
      }

      &:nth-child(2) {
        padding: 11px 40px 14px 0px;
        .body_cards_card_img_wrapper {
          margin-left: 14px;
        }
        & img {
          max-width: 194px;
          width: 100%;
        }
      }

      &:nth-child(3) {
        padding: 30px 40px 20px 0px;
        .body_cards_card_img_wrapper {
          margin-left: 35px;
        }
        & img {
          max-width: 196px;
          max-height: 191px;
          width: 100%;
        }
      }

      &:nth-child(4) {
        padding: 24px 40px 24px 0px;
        .body_cards_card_img_wrapper {
          margin-left: 24px;
        }
        & img {
          max-width: 168px;
          width: 100%;
        }
      }

      @media (max-width: 650px) {
        padding-right: 27px !important;
        padding-left: 27px !important;
      }
    }

    &_card {
      display: flex;
      align-items: center;
      column-gap: 10px;
      height: 100%;

      @media (max-width: 1200px) {
        display: grid;
        grid-template-columns: 230px 1fr;
      }

      @media (max-width: 650px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }

      &_img {
        &_wrapper {
          position: relative;
          width: fit-content;

          @media (max-width: 650px) {
            margin: 0 auto 10px !important;
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            width: 10px;
            height: 10px;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            border-radius: 50%;

            box-shadow: 0px 0px 116px 33px var(--beige);

            @media (max-width: 768px) {
              box-shadow: 0px 0px 66px 33px var(--beige);
            }
          }
        }
      }
      &_info {
        max-width: 263px;
        margin-left: auto;

        @media (max-width: 1200px) {
          max-width: 100%;
          margin-left: 44px;
        }

        @media (max-width: 650px) {
          text-align: center;
          margin-left: 0px;
        }

        & h4 {
          margin-bottom: 12px;
        }

        & p {
          margin-bottom: 20px;
        }

        &_link {
          @media (max-width: 650px) {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
