@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');

:root {
  --bg: #08090d;
  --bg2: #1a181e;
  --beige: #feba1e;
  --beige2: #fff7e5;
  --grey: #9097a7;
  --white: #ffffff;
  --gradient_text: linear-gradient(54.54deg, #95989f 9.81%, #e9e9e7 65.85%);
  --button_bg: linear-gradient(
    46.48deg,
    #d56203 5.77%,
    #fdb001 48.61%,
    #fefedc 91.45%
  );
}

body {
  background-color: var(--bg);
  overflow: hidden;
}

* {
  font-family: 'Geologica';
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
  user-select: none;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input {
  border: none;
  outline: none;
}

textarea:focus,
input:focus {
  font-family: 'Geologica';
  outline: none;
}

input::placeholder {
  font-family: 'Geologica';
}

html {
  overflow-x: hidden;
  user-select: none;
}

body {
  overflow-x: hidden;
}

button {
  border: none;
  outline: none;
  background-color: inherit;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:active,
input:focus {
  border: 0;
  outline: 0;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.default_hover {
  cursor: pointer;
  @media (any-hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
}

.hover_standart {
  transition: 0.5s;
  cursor: pointer;
}

.hover_standart:hover {
  transition: 0.5s;
  opacity: 0.7;
}

.landing_btn {
  padding: 12px 24px;
  border-radius: 68px;
  background: var(--button_bg);
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--bg);
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  width: fit-content;

  cursor: pointer;
  transition: all 0.3s ease 0s;
}
