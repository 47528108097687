.title {
  text-align: center;
  & h2 {
    margin-bottom: 16px;
  }
  & p {
    max-width: 866px;
    margin: 0 auto 32px;
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-bottom: 181px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
    margin-bottom: 100px;
  }

  &_card {
    width: 100%;
    padding: 24px 20px 0px;
    &_header {
      display: flex;
      align-items: center;
      column-gap: 26px;
      padding-bottom: 16px;
      margin-bottom: 20px;

      position: relative;

      & p {
        font-size: 14px;
        font-weight: 600;
        line-height: 17.5px;
        color: var(--beige2);
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;

        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0.96%,
          rgba(255, 255, 255, 0.1) 52.03%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      &_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        border-radius: 8px;

        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.06) 0%,
          rgba(255, 255, 255, 0) 100%
        );

        & img {
          width: 100%;
          height: auto;
          // width: 28px;
          // height: 28px;
        }
        // position: relative;

        // &::after {
        //   pointer-events: none;
        //   content: '';
        //   position: absolute;
        //   z-index: -1;
        //   top: 0;
        //   left: 0;
        //   right: 0;
        //   bottom: 0;
        //   border-radius: 8px;

        //   border: 1px solid transparent;
        //   background: linear-gradient(
        //       356deg,
        //       rgba(255, 255, 255, 0.1) -0.35%,
        //       rgba(255, 255, 255, 0.02) 103.27%
        //     )
        //     border-box;

        //   -webkit-mask:
        //     linear-gradient(#fff 0 0) padding-box,
        //     linear-gradient(#fff 0 0);
        //   -webkit-mask-composite: destination-out;
        //   mask-composite: exclude;
        // }

        // &::before {
        //   content: '';
        //   display: block;
        //   position: absolute;
        //   width: 0px;
        //   height: 0px;
        //   top: 50%;
        //   left: 50%;
        //   box-shadow: 0px 5px 15px 7px var(--beige);
        // }
      }
    }

    &_body {
      display: grid;
      row-gap: 20px;
      margin-bottom: 20px;
      &_item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_left {
          display: flex;
          column-gap: 8px;
          font-size: 12px;
          font-weight: 400;
          line-height: 17.32px;

          & p {
            background: var(--gradient_text);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        &_right {
          font-size: 12px;
          font-weight: 400;
          line-height: 17.32px;
          color: var(--beige);
        }
      }
    }

    &_button {
      display: block;
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.068);
      border-radius: 68px;

      background: linear-gradient(
        356.37deg,
        rgba(255, 255, 255, 0.05) -0.35%,
        rgba(255, 255, 255, 0.01) 103.27%
      );

      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      text-align: center;
      color: var(--beige2);

      padding: 2px;
      padding: 12px 15px;

      transition: all 0.3s ease 0s;
      @media (any-hover: hover) {
        &:hover {
          opacity: 0.7;
        }
      }
    }

    &_chart {
      min-height: 80px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin: 0 -20px;
      & img {
        max-width: 100%;
        width: 100%;
      }
      // &:nth-child(4) {
      //   & img {
      //     height: 60px;
      //   }
      // }
    }
  }
}
