.title_l {
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  background: var(--gradient_text);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sub_title_l {
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  background: var(--gradient_text);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 32px;
  }
}

.title_s {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  background: var(--gradient_text);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title_x {
  font-size: 14px;
  font-weight: 600;
  line-height: 17.5px;
  background: var(--gradient_text);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text {
  font-size: 14px;
  font-weight: 300;
  line-height: 17.5px;
  color: var(--grey);
}

.text_light {
  font-size: 14px;
  font-weight: 300;
  line-height: 17.5px;
  color: var(--beige2);
}

.text_light_welcome {
  font-size: 14px;
  font-weight: 300;
  line-height: 17.5px;
  color: var(--beige2);
  margin-top: 24px;
}

.text_accent {
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  background: var(--gradient_text);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border_element {
  border-radius: 8px;
  border: 1px solid #ffffff0d;
  background: #ffffff05;
  backdrop-filter: blur(4px);
}
