.link {
  display: block;
  border-radius: 68px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
  color: var(--beige2);

  padding: 2px;
  padding: 12px 27px;
  width: fit-content;
  transition: all 0.3s ease 0s;
  position: relative;
  border-radius: 68px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(15px);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 68px;

    border: 1px solid transparent;
    background: linear-gradient(
        356deg,
        rgba(255, 255, 255, 0.1) -0.35%,
        rgba(255, 255, 255, 0.02) 103.27%
      )
      border-box;

    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}
