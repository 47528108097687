.wrapper {
  margin-bottom: 60px;

  border-radius: 8px;

  @media (min-width: 1200px) {
    backdrop-filter: blur(10px);
  }

  position: relative;

  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;

    border: 1px solid transparent;
    background: linear-gradient(
        356deg,
        rgba(255, 255, 255, 0.1) -0.35%,
        rgba(255, 255, 255, 0.02) 103.27%
      )
      border-box;

    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}

.body {
  padding: 56px 63px;
  border-radius: 8px;

  position: relative;
  z-index: 20;

  background-image: url('../../../../../../public/assets/img/landing/community_decoration.svg');
  background-repeat: no-repeat;
  background-position-x: right;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 0px;
    height: 0px;
    top: 56%;
    right: 16%;
    box-shadow: 0px 0px 154px 44px var(--beige);

    @media (max-width: 900px) {
      top: 73%;
      right: 21%;
      box-shadow: 0px 0px 154px 44px var(--beige);
    }
  }

  & > h2 {
    font-size: 38px;
    font-weight: 600;
    line-height: 47.5px;
    background: var(--gradient_text);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    margin-bottom: 16px;
  }

  & > p {
    margin-bottom: 24px;
  }

  &_links {
    display: flex;
    column-gap: 20px;
    position: relative;
    z-index: 1;

    @media (max-width: 768px) {
      flex-direction: column;
      row-gap: 9px;
      & a {
        width: 100%;
      }
    }

    &_inner {
      display: flex;
      align-items: center;
      column-gap: 20px;
    }
  }

  @media (max-width: 1200px) {
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 70%;

      background-image: url('../../../../../../public/assets/img/landing/community_decoration_mobile.svg');
      background-repeat: no-repeat;
      // background-position: center bottom;
      background-size: cover;

      @media (max-width: 650px) {
        background-position-x: 35%;
      }
    }

    background-image: none;
    padding-bottom: 147px;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      max-width: 248px;
    }

    & p {
      max-width: 224px;
    }
  }

  @media (max-width: 768px) {
    padding: 49px 30px 115px;
  }
}

.link {
  display: block;
  border-radius: 68px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
  color: var(--beige2);

  padding: 2px;
  padding: 12px 27px;
  width: fit-content;
  transition: all 0.3s ease 0s;
  position: relative;
  border-radius: 68px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(15px);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 68px;

    border: 1px solid transparent;
    background: linear-gradient(
        356deg,
        rgba(255, 255, 255, 0.1) -0.35%,
        rgba(255, 255, 255, 0.02) 103.27%
      )
      border-box;

    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}
