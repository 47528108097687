.body {
  padding: 12px 0px;

  display: flex;
  align-items: center;

  &_logo {
    width: 177px;
    height: 24px;
    display: flex;
    align-items: center;

    margin-right: 40px;
    & img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }

    & a {
      display: flex;
      align-items: center;
    }
  }

  &_anchors {
    display: flex;
    column-gap: 20px;

    & span {
      cursor: pointer;
      font-size: 12px;
      font-weight: 300;
      line-height: 15px;
      color: var(--grey);
      transition: all 0.3s ease 0s;
      @media (any-hover: hover) {
        &:hover {
          color: var(--beige2);
        }
      }
    }
  }

  &_button {
    margin-left: auto;

    &_inner {
      display: flex;
      align-items: center;
      column-gap: 14px;
      color: var(--bg);
    }

    &_mobile {
      display: none;
      margin-left: auto;
      @media (max-width: 768px) {
        display: block;
      }
      & a {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background-color: #ffffff0d;
      }
    }
  }

  @media (max-width: 768px) {
    &_anchors,
    &_button {
      display: none;
    }
  }
}
