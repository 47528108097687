.wrapper {
  max-width: 1170px;
  padding: 0px 15px;
  margin: 0 auto;

  position: relative;
}

.circle {
  position: absolute;
  pointer-events: none;

  width: 2014px;
  height: 2014px;
  // width: 900px;
  // height: 900px;
  // background-color: #f9e6d312;
  // border-radius: 50%;
  // filter: blur(180px);

  background-image: url('../../../../public/assets/img/landing/Ellipse_T.svg');
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    z-index: 10;
  }
}

.circle_1 {
  top: -23%;
  left: -800px;
  z-index: 10;
  @media (max-width: 768px) {
    top: -824px;
    left: -419px;
  }
}

.circle_2 {
  top: 10%;
  right: -942px;
  @media (max-width: 768px) {
    top: 9%;
    right: -337px;
    width: 1700px;
    height: 1700px;
    z-index: 10;
  }
}

.circle_3 {
  bottom: -7%;
  left: -1009px;
  @media (max-width: 768px) {
    bottom: 0%;
    left: -567px;
    z-index: -1;
  }
}

.circle_4 {
  display: none;
  @media (max-width: 768px) {
    display: block;
    bottom: 40%;
    right: -1233px;
  }
}

.circle_5 {
  display: none;

  @media (max-width: 768px) {
    display: block;
    bottom: 17%;
    right: -353px;
  }
}

.candles {
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translateX(-50%);

  width: 1920px;
  height: 1164px;

  background-image: url('../../../../public/assets/img/landing/candles_bg.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  @media (max-width: 1920px) {
    height: 1000px;
  }

  @media (max-width: 1600px) {
    top: 47%;
  }

  @media (max-width: 1200px) {
    top: 61%;
    height: 900px;
    background-position-x: 70%;
    background-image: url('../../../../public/assets/img/landing/candles_bg_mobile.svg');
  }

  @media (max-width: 650px) {
    top: 63%;
  }
  @media (max-width: 600px) {
    top: 61.5%;
    left: 66%;
  }
}
