.body {
  display: grid;
  grid-template-columns: 1fr 1fr;

  margin-bottom: 120px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    align-items: center;
    row-gap: 34px;
    margin-bottom: 91px;
  }

  @media (max-width: 650px) {
    margin-bottom: 80px;
  }

  &_img {
    & img {
      display: block;
      max-width: 458px;
      width: 100%;
      margin: 0 auto;
    }
    @media (max-width: 1200px) {
      order: 2;
    }
  }

  &_text {
    @media (max-width: 1200px) {
      text-align: center;
    }
    & h2 {
      margin-bottom: 16px;
    }

    & > p {
      margin-bottom: 32px;
    }
  }

  &_items {
    margin-bottom: 32px;
    &_item {
      display: flex;
      column-gap: 26px;
      align-items: flex-start;
      & img {
        max-width: 58px;
        width: 58px;
      }

      @media (max-width: 1200px) {
        max-width: 498px;
        margin: 0 auto;
      }
      text-align: left;
      &:not(:last-child) {
        margin-bottom: 28px;
      }
      & p {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        background: var(--gradient_text);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 8px;
        text-align: left;
      }
    }
  }

  &_button {
    @media (max-width: 1200px) {
      display: flex;
      justify-content: center;
    }
  }
}

.order {
  @media (max-width: 1200px) {
    order: 2;
  }
}
